<script>
/**
 * E-commerce Settings Page
 * @version 1.0.0
 * @since 3.16.0
 */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { openConfigWindow } from '@/util/configWindow';
import { INTEGRATION_STATUSES, INTEGRATIONS_TYPES, LOADING_KEY } from '@/util/constants';
import bus from '@/util/trayBus';
import Page from '@/views/admin/SinglePage.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzButton from '@/components/ui/Button/EzButton';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { supplierGetConfigWizardURL } from '@/api/endpoints/supplier-tray';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzButton,
    Page,
    VStatusBadge,
    EzConfirmationModal,
    EzButtonDropdown,
    EzLoader,
  },
  data() {
    return {
      isUpdate: false,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['eCommerceIntegration']),
    ...mapGetters('entities/distributors', [
      'isSelectedEcommerceConnected',
      'isSelectedEcommerceNeedToUpdate',
      'isSelectedEcommerceNotEnabled',
      'isSelectedEcommerceNotConnected',
    ]),
    canSeeTabs() {
      return this.isSelectedEcommerceConnected || this.isSelectedEcommerceNeedToUpdate;
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.DISTRIBUTOR_DISCONNECT_INTEGRATIONS]);
    },
    allSet() {
      return this.eCommerceIntegration?.status === INTEGRATION_STATUSES.connected;
    },
    isTrayDefault() {
      return this.eCommerceIntegration?.type === INTEGRATIONS_TYPES.default;
    },
    hasUnmappedWarehouses() {
      return this.eCommerceIntegration.actionNeeded?.warehouseNotMapped;
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorEnableConnectedIntegrations',
      'distributorDisconnectIntegrations',
    ]),
    ...mapMutations('entities/distributors', [
      'UPDATE_SELECTED_INTEGRATION',
      'CLEAR_SELECTED_INTEGRATION',
    ]),
    async update() {
      this.isUpdate = true;
      const { data } = await supplierGetConfigWizardURL(this.eCommerceIntegration.instanceId);
      const configWindow = openConfigWindow();
      configWindow.location = data.data;
    },
    async confirmDisconnect() {
      await this.distributorDisconnectIntegrations({
        solutionId: this.eCommerceIntegration.instanceId,
      });

      const integrationTitle = this.eCommerceIntegration.title;
      this.CLEAR_SELECTED_INTEGRATION();
      this.$refs.disconnectModal.close();
      await this.$router.push({
        name: 'distributor-integrations',
        params: {
          flash: {
            type: 'success',
            title: `Integration ${integrationTitle} successfully disconnected!`,
          },
        },
      });
    },
    async enableIntegration() {
      const { data: { data } } = await this.distributorEnableConnectedIntegrations({
        solutionId: this.eCommerceIntegration.instanceId,
      });
      this.UPDATE_SELECTED_INTEGRATION(data);
      await flash.success({
        title: this.$t('integrations.tray.flashSuccessTitle'),
        delay: 6000,
      });
      this.isUpdate = false;
    },
    onUpdateFinished() {
      if (!this.isUpdate) return;
      if (this.isSelectedEcommerceNeedToUpdate || this.isSelectedEcommerceConnected) {
        this.enableIntegration();
      }
    },
    onUpdateNotFinished() {
      if (this.isSelectedEcommerceNeedToUpdate || this.isSelectedEcommerceConnected) {
        this.$router.push({ name: 'distributor-integrations' });
      }
    },
  },
  mounted() {
    bus.$on('finished', this.onUpdateFinished);
    bus.$on('notFinished', this.onUpdateNotFinished);
  },
};
</script>
<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-integrations' }">
        <font-awesome-icon icon="arrow-left" />
        Back to Supplier Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center title">
        <img :src="eCommerceIntegration.icon" width="32" height="32" alt="" />
        <h2 class="m-0 ml-8 xero-title">{{ eCommerceIntegration.title }}</h2>
        <template>
          <v-status-badge type="orange" v-if="isSelectedEcommerceNotEnabled">{{
            $t('integrations.actions.actionNeeded')
          }}</v-status-badge>
          <v-status-badge type="green" v-if="isSelectedEcommerceConnected && allSet">{{
            $t('integrations.actions.allSet')
          }}</v-status-badge>
          <v-status-badge type="blue" v-if="isSelectedEcommerceNeedToUpdate">{{
            $t('integrations.actions.actionUpdate')
          }}</v-status-badge>
        </template>
      </div>
    </template>
    <template #actions>
      <div>
        <ez-button-dropdown v-if="!isSelectedEcommerceNotConnected" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button type="link" @click="update">
              Update integration
            </ez-button>
            <ez-button v-open="'disconnectModal'" type="red-link">
              {{ $t('global.actions.disconnect') }}
            </ez-button>
          </template>
        </ez-button-dropdown>
      </div>
    </template>
    <template #navigation v-if="!isTrayDefault">
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="canSeeTabs ? 'router-link' : 'span'"
            :to="canSeeTabs ? { name: 'distributor-e-commerce-products' } : null"
          >
            Products
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="canSeeTabs ? 'router-link' : 'span'"
            :to="canSeeTabs ? { name: 'distributor-e-commerce-warehouses' } : null"
          >
            Warehouses
            <span v-if="hasUnmappedWarehouses" class="navigation-link--warning">
              <font-awesome-icon icon="exclamation-circle" />
            </span>
          </component>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="disconnectModal" type="red">
      <template #title>Disconnect from {{ eCommerceIntegration.title }}?</template>
      <template #content>
        <div class="xero-description">
          You’re about to disconnect your {{ platformName }} account from {{ eCommerceIntegration.title }}.
        </div>
      </template>
      <template #footer>
        <ez-button v-close="'disconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </page>
</template>
<style lang="scss" scoped>
.xero-title {
  @include font-size(24px);
  font-weight: 500;
}
.title {
  img {
    border: 1px solid #e1e5ed;
    border-radius: 50%;
  }
}
.navigation-link {
  &--warning {
    margin-left: 4px;
    @include font-size(16px);
    color: $color-primary-orange;
  }
}
</style>
