var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ez-number-input"},[_c('div',{staticClass:"input-group"},[(_vm.label)?_c('label',{staticClass:"input-group__label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        placement: 'top',
        content: _vm.formattedErrorTooltip,
        show: _vm.errorTooltipShow,
        trigger: 'manual',
        classes: ['tooltip--reset-margin', 'tooltip--danger'],
      }),expression:"{\n        placement: 'top',\n        content: formattedErrorTooltip,\n        show: errorTooltipShow,\n        trigger: 'manual',\n        classes: ['tooltip--reset-margin', 'tooltip--danger'],\n      }"}],class:['input-group__input', { 'input-group__input--disabled': _vm.disabled }]},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: _vm.tooltipPlacement,
          content: !_vm.isDivisible ? 'Round down' : _vm.decreaseTooltipContent,
          classes: ['tooltip--reset-margin'],
        }),expression:"{\n          placement: tooltipPlacement,\n          content: !isDivisible ? 'Round down' : decreaseTooltipContent,\n          classes: ['tooltip--reset-margin'],\n        }"}],attrs:{"data-cy":`${_vm.dataCy}-decreaseQuantity`,"type":"button","disabled":_vm.minusDisabled || _vm.disabled},on:{"click":_vm.onMinusClick}},[_c('font-awesome-icon',{attrs:{"size":"xs","icon":"minus"}})],1),(!_vm.isFractional)?_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.val),expression:"val",modifiers:{"number":true}}],class:[{ 'input-group__input--invalid': _vm.inputInvalid }],attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"type":"number","data-cy":_vm.dataCy},domProps:{"value":(_vm.val)},on:{"input":[function($event){if($event.target.composing)return;_vm.val=_vm._n($event.target.value)},_vm.onChange],"keypress":_vm.keyPress,"blur":function($event){return _vm.$forceUpdate()}}}):_c('ez-mask-input',{class:['mask-input', { 'mask-input--invalid': _vm.inputInvalid }],attrs:{"form-key":_vm.formKey,"name":_vm.name,"value":_vm.val,"disabled":_vm.disabled,"precision":4,"price-prefix":false,"type":"inline","allow-negative-value":_vm.allowNegativeValue,"data-cy":_vm.dataCy},on:{"input":_vm.onMaskChange}}),(_vm.hasSuffixSlot)?_c('div',{staticClass:"input-group__input__suffix"},[_vm._t("suffix")],2):_vm._e(),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: _vm.tooltipPlacement,
          content: !_vm.isDivisible ? 'Round up' : _vm.increaseTooltipContent,
          classes: ['tooltip--reset-margin'],
        }),expression:"{\n          placement: tooltipPlacement,\n          content: !isDivisible ? 'Round up' : increaseTooltipContent,\n          classes: ['tooltip--reset-margin'],\n        }"}],attrs:{"data-cy":`${_vm.dataCy}-increaseQuantity`,"type":"button","disabled":_vm.plusDisabled || _vm.disabled},on:{"click":_vm.onPlusClick}},[_c('font-awesome-icon',{attrs:{"size":"xs","icon":"plus"}})],1)],1),(_vm.hasServerErrorMessage)?_c('div',{staticClass:"input-group__error"},[_vm._v(_vm._s(_vm.serverError.message))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }