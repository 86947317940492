<script>
import EzButton from '@/components/ui/Button/EzButton';
import EzInput from '@/components/ui/Input/EzInput';
import { openConfigWindow } from '@/util/configWindow';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';

export default {
  name: 'installStep',
  components: {
    EzButton,
    EzInput,
    EzLoader,
  },
  props: {
    queryData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isNameValid: false,
      formKey: 'createSolution',
      nameVal: '',
      availableData: [],
    };
  },
  computed: {
    ...mapState('entities/distributors', [
      'eCommerceIntegration',
    ]),
    ...mapState('entities/users', [
      'loggedUser',
    ]),
    platformName() {
      return this.eCommerceIntegration?.providerName || 'Shopify';
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_CREATE_INTEGRATIONS,
      ]);
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'SET_SELECTED_INTEGRATION',
      'UPDATE_SELECTED_INTEGRATION',
    ]),
    ...mapActions('entities/distributors', [
      'distributorCreateIntegration',
      'distributorFetchAvailableIntegrations',
    ]),
    async onSubmit() {
      try {
        const { data } = await this.distributorCreateIntegration({
          body: {
            data: this.queryData,
            name: this.nameVal,
            type: this.eCommerceIntegration.type,
            solutionId: this.queryData.state,
          },
          formKey: this.formKey,
        });
        const configWindow = openConfigWindow();
        configWindow.location = data.data.configUrl;
        this.UPDATE_SELECTED_INTEGRATION(data.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    onNameInputChange(val) {
      this.isNameValid = !!val.trim();
      this.nameVal = val;
    },
  },
  async created() {
    const { data } = await this.distributorFetchAvailableIntegrations({
      query: '',
    });
    this.availableData = data.data.find(sol => sol.id === this.queryData.state) || {};
    this.SET_SELECTED_INTEGRATION(this.availableData);
  },
};
</script>

<template>
  <div class="redirect-wrapper">
    <h3>Integration Setup Steps</h3>
    <hr />
    <h4 class="mb-0">1. Name your Account</h4>
    <div class="redirect-wrapper--content">
      <ez-input
        ref="nameInput"
        class="integration-name mt-8"
        label="Account Name*"
        :formKey="formKey"
        name="name"
        :placeholder="`${platformName} - ${loggedUser.distributor.name}`"
        @onChange="onNameInputChange"
      />
      <div class="info">
        <p>This name should uniquely represent your store. It will be shown in the order history
          for orders coming from {{ $t('integrations.shopify.name') }}.</p>
        <hr>
        <h4>2. Manage your integration settings</h4>
        <p>
          Set up the rules for updating order statuses between the platforms.
        </p>
        <hr>
        <h4>3. Map Warehouses</h4>
        <p>
          Map the Warehouse from {{ oezPlatformName }} to the Location in {{ $t('integrations.shopify.name') }}
          to ensure that inventory levels are reported properly.
        </p>
        <hr>
        <h4>4. Map Products</h4>
        <p>
          Map your {{ $t('integrations.shopify.name') }} products to those from {{ oezPlatformName }}. Add
          multiple products or vary the quantity to create bundles or packs.
        </p>
      </div>
      <ez-button @click="onSubmit" class="mt-24" form-type="submit" :disabled="!isNameValid">
        Save and Continue
      </ez-button>
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style scoped lang="scss">
.redirect-wrapper {
  width: 400px;
  margin: 0 auto;

  &--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .integration-name {
      width: 100%;
    }

    .info {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      h4 {
        @include font-size(14px, 22px);
        color: $color-gray-25;
        font-weight: 600;
        margin: 0;
      }
      p {
        @include font-size(14px, 20px);
        color: $color-gray-6C;
        margin: 4px 0 0 0;
      }
    }
  }
}
</style>
