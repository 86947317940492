<script>
/**
 * MapProductsModal
 * @version 1.0.0
 * @since
 */

import { mapState, mapGetters, mapActions } from 'vuex';
import { clone } from '@/util/utils';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import EzAlert from '@/components/ui/Alert/EzAlert';
import EzButton from '@/components/ui/Button/EzButton';
import EzFormModal from '@/components/ui/Modal/EzFormModal';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzNumberInput from '@/components/ui/NumberInput';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

const Event = {
  open: 'open',
  close: 'close',
  submit: 'submit',
};

export default {
  name: 'MapProductsModal',
  components: {
    EzAlert,
    EzButton,
    EzFormModal,
    EzEntityInfo,
    EzNumberInput,
    VSelectSearch,
  },
  data() {
    return {
      products: [],
      mappedProducts: [],
      activeProduct: null,
      idTrack: 0,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['eCommerceIntegration']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.DISTRIBUTOR_GET_PRODUCTS]);
    },
    haveInvalidProducts() {
      return this.mappedProducts.some(p => p.product.id.toString().includes('null'));
    },
    haveInvalidQuantities() {
      return this.mappedProducts.some(p => !p.quantity);
    },
    saveDisabled() {
      if (this.areAllUnmapped) return false;
      if (this.haveInvalidProducts || this.haveInvalidQuantities) return true;
      if (!this.haveNewProducts && !this.haveNewQuantities) return true;
      return false;
    },
    haveNewProducts() {
      const oldIds = this.activeProduct?.mappedProducts.map(p => p?.product.id).filter(Boolean);
      const newIds = this.mappedProducts
        .map(p => p?.product.id)
        .filter(Boolean)
        .filter(pId => !pId.toString().includes('null'));

      return oldIds?.sort().join('') !== newIds.sort().join('');
    },
    haveNewQuantities() {
      const oldQuants = this.activeProduct?.mappedProducts.map(p => p?.quantity).filter(Boolean);
      const newQuants = this.mappedProducts.map(p => p?.quantity).filter(Boolean);

      return oldQuants?.sort().join('') !== newQuants.sort().join('');
    },
    haveSuggested() {
      return !!this.activeProduct?.suggestedProduct;
    },
    showSuggested() {
      return (
        this.haveSuggested && this.haveInvalidProducts && !this.activeProduct?.mappedProducts.length
      );
    },
    areAllUnmapped() {
      const currLength = this.mappedProducts.length;
      if (!currLength) return false;

      const prevLength = !!this.activeProduct?.mappedProducts.length;
      const firstInvalid = this.isProductInvalid(this.mappedProducts[0].product);

      return currLength === 1 && prevLength && firstInvalid;
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/orders', ['fetchDistributorProductsForVenue']),
    clearComponentState() {
      this.idTrack = 0;
      this.activeProduct = null;
    },
    open(product) {
      if (this.$refs.selectSearch) this.$refs.selectSearch.forEach(s => s.reset());

      const placeholder = {
        product: {
          ...(product.suggestedProduct ?? { id: `null-${(this.idTrack += 1)}` }),
        },
        quantity: 1,
      };
      const haveMapped = !!product.mappedProducts.length;
      const products = haveMapped ? product.mappedProducts : [placeholder];

      this.setProducts(product.name);
      this.activeProduct = product;
      this.mappedProducts = clone(products);

      this.$refs.modal.open();
      this.$emit(Event.open);
    },
    close() {
      this.$refs.modal.close();
      this.$emit(Event.close);
    },
    onCloseModal() {
      this.clearComponentState();
    },
    addMappedProduct() {
      this.mappedProducts = [
        ...this.mappedProducts,
        { product: { id: `null-${(this.idTrack += 1)}` }, quantity: 1 },
      ];
    },
    removeMappedProduct(id) {
      this.mappedProducts = this.mappedProducts.filter(p => p.product.id !== id);
    },
    updateProductData(e, id) {
      const idx = this.mappedProducts.findIndex(p => p.product.id === id);
      const insert = { product: { id: `null-${(this.idTrack += 1)}` }, quantity: 1 };

      if (!e.reset) insert.product = { ...e };

      this.mappedProducts.splice(idx, 1, { ...insert });
    },
    updateProductQuantity(e, id) {
      const idx = this.mappedProducts.findIndex(p => p.product.id === id);
      this.mappedProducts[idx].quantity = e;
    },
    confirmProductMapping() {
      const mappedProducts = this.mappedProducts.filter(
        p => !p.product.id.toString().includes('null'),
      );
      this.$emit(Event.submit, { ...this.activeProduct, mappedProducts });
      this.close();
    },
    findSelected(id, idx) {
      const isSuggested = this.activeProduct?.suggestedProduct?.id === id;
      if (!idx && this.haveSuggested && isSuggested) return this.activeProduct.suggestedProduct;
      return this.activeProduct?.mappedProducts.find(p => p.product.id === id)?.product;
    },
    isSuggestedProduct(sku) {
      if (!this.haveSuggested) return false;

      const alreadyMapped = this.activeProduct?.mappedProducts.find(p => p.product.sku === sku);
      if (alreadyMapped) return false;

      return sku === this.activeProduct?.suggestedProduct?.sku;
    },
    transformer(data) {
      return data.filter(({ id }) => !this.mappedProducts.map(p => p.product.id).includes(id));
    },
    getSearchTermFromName(name = '', idx = 1) {
      let raw;
      if (!idx && this.showSuggested) raw = this.activeProduct?.suggestedProduct?.name;
      else raw = name || this.activeProduct?.name;

      const sanitized = raw?.match(/\w+/g);
      return sanitized?.splice(0, 2).join(' ');
    },
    async setProducts(name) {
      const term = this.getSearchTermFromName(name);
      const { data } = await this.fetchDistributorProductsForVenue({ limit: 10, term });

      this.products = data.data;
    },
    dropdownOptions(idx) {
      if (!idx && this.haveSuggested) return [this.activeProduct?.suggestedProduct];
      return this.products;
    },
    isFractionalUnit(product) {
      return product.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    minIncrementValue(product) {
      if (this.isProductInvalid(product)) return 1;
      return this.isFractionalUnit(product) ? 0.01 : 1;
    },
    isProductInvalid(product) {
      return product.id.toString().includes('null');
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onCloseModal">
    <template #title>
      <div>Map Products</div>
    </template>

    <template #content>
      <div>
        <div
          v-for="({ product, quantity }, idx) in mappedProducts"
          :key="idx"
          class="mapped-product mb-16"
        >
          <hr v-if="idx > 0" />
          <div class="mapped-product__header">
            <span class="mapped-product__header__label mb-12">PRODUCT {{ idx + 1 }}</span>
            <ez-button
              v-if="idx > 0"
              type="link"
              class="mapped-product__header__remove-btn"
              @click="removeMappedProduct(product.id)"
            >
              <font-awesome-icon icon="times" />Remove
            </ez-button>
            <ez-alert
              v-if="isSuggestedProduct(product.sku) && !idx"
              variant="disclaimer"
              custom-class="suggested-product-alert mt-0 mb-12"
            >
              <template #icon><font-awesome-icon icon="exclamation-circle"/></template>
              <span>
                This {{ platformName }} product is suggested based on the matching SKU.
              </span>
            </ez-alert>
          </div>
          <v-select-search
            ref="selectSearch"
            class="products-dropdown mb-12"
            :label="`${platformName} Product`"
            search="/distributor/search/products"
            :minLength="2"
            placeholder="Select Product"
            :searchPlaceholder="getSearchTermFromName('', idx)"
            :data="dropdownOptions(idx)"
            :transformer="transformer"
            :selected="findSelected(product.id, idx)"
            @selected="obj => updateProductData(obj, product.id)"
            isFullWidth
          >
            <template #result="{result}">
              <ez-entity-info
                :imgUrl="result.image"
                imgWidth="2rem"
                imgHeight="2rem"
                imgBorderRadius="3px"
              >
                <span class="entity-info__name">{{ result.name }}</span>
                <span class="entity-info__sku">{{ result.sku }}</span>
              </ez-entity-info>
            </template>
          </v-select-search>
          <ez-number-input
            class="mb-12"
            @click.stop
            name="quantity"
            label="Quantity"
            formKey=""
            :value="quantity"
            :minValue="minIncrementValue(product)"
            :increment="minIncrementValue(product)"
            :is-fractional="isFractionalUnit(product)"
            :disabled="isProductInvalid(product)"
            @onChange="$event => updateProductQuantity($event, product.id)"
          />
        </div>
        <ez-button
          @click="addMappedProduct"
          class="add-unit"
          type="secondary"
          :disabled="haveInvalidProducts"
          :isFullWidth="true"
        >
          <font-awesome-icon icon="plus" />Add Additional Product
        </ez-button>
      </div>
    </template>

    <template #footer>
      <div>
        <ez-button type="link" @click="close">Cancel</ez-button>
        <ez-button :disabled="saveDisabled" :is-loading="isLoading" @click="confirmProductMapping">
          Save Changes
        </ez-button>
      </div>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.suggested-product-alert {
  flex: 1 0 100%;
  align-items: start;
  justify-content: start;
  height: auto;
}

.mapped-product {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__label {
      @include font-size(12px, 16px);
      font-weight: 700;
      color: $color-gray-6C;
    }

    &__remove-btn svg {
      margin-right: 0.5rem;
    }
  }
}

.products-dropdown {
  :deep() .select-search {
    &__trigger span {
      overflow: hidden;
      width: calc(80% - (10px + 2 * 10px));
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;

      &:not(.select-search__value) {
        color: $color-gray-6C;
      }
    }

    &__list {
      max-height: 274px;
    }
  }
}

:deep() .entity-info {
  align-items: center;

  &__text {
    flex-direction: column;
    align-items: start;
  }

  &__name {
    @include font-size(14px, 20px);
    font-weight: 600;
    color: $color-gray-25;
  }

  &__sku {
    @include font-size(12px, 14px);
    font-weight: 500;
    color: $color-gray-6C;
  }
}

:deep() .confirmation-modal {
  .modal {
    &__title {
      margin-bottom: 16px;
    }

    &__text {
      padding-bottom: 0;
      text-align: left;
    }

    &__footer {
      margin-top: 16px;
      padding: 0;
    }
  }
}
</style>
