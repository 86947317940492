import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#faf24517-1122-4dfe-bd86-0c3c89eab80f}
 *
 * @param {String} id instance id
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierGetWarehouses(id, loadingKey = LOADING_KEY.SUPPLIER_GET_WAREHOUSES) {
  return httpService.get(`/distributor/tray/solution-instances/${id}/e-commerce/warehouses`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#d91533c5-133a-4a25-8c58-8afe0ff3055f}
 *
 * @param {String} id instance id
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierGetProducts(
  id,
  loadingKey = LOADING_KEY.SUPPLIER_GET_PRODUCTS,
  { nextId, limit = 25 } = { nextId: null, limit: 25 },
) {
  return httpService.get(`/distributor/tray/solution-instances/${id}/e-commerce/products`, {
    params: {
      nextId,
      limit,
    },
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#c5b7052d-7211-4103-8293-43d78ea26036}
 *
 * @param {String} id instance id
 * @param { { warehouses: { id: string; providerId: (string|null); }[] } } data
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierMapWarehouses(id, data, loadingKey = LOADING_KEY.SUPPLIER_MAP_WAREHOUSES) {
  return httpService.put(
    `/distributor/tray/solution-instances/${id}/e-commerce/warehouses/mappings`,
    data,
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x#f0af9341-a497-48ea-b710-3848c0b9d7ee}
 *
 * @param {String|null} id instance id
 * @param { { products: { id: string; mappedProducts: { id: string; quantity: number; }[] }[] } } data
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierMapProducts(id, data, loadingKey = LOADING_KEY.SUPPLIER_MAP_PRODUCTS) {
  return httpService.put(
    `/distributor/tray/solution-instances/${id}/e-commerce/products/mappings`,
    data,
    {
      headers: {
        loadingKey,
      },
    },
  );
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#6b419393-acde-44a3-b7db-80d20fe202b6}
 *
 * @param {String} id instance id
 * @param {Object} data
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierPushProducts(id, data, loadingKey = LOADING_KEY.SUPPLIER_PUSH_PRODUCTS) {
  return httpService.put(`/distributor/tray/solution-instances/${id}/e-commerce/products`, data, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#16d0ea85-ece5-4c80-8b51-9dcfcf567b46}
 *
 * @param {String} id instance id
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierGetConfigWizardURL(
  id,
  loadingKey = LOADING_KEY.SUPPLIER_GET_CONFIG_WIZARD_URL,
) {
  return httpService.get(`/distributor/tray/solution-instances/${id}/config-url`, {
    headers: {
      loadingKey,
    },
  });
}

/**
 * @see {https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#a5fa24d0-6c16-4271-88fc-c5f1109268ac}
 *
 * @param {String} id instance id
 * @return {Promise<AxiosResponse<any>>}
 */
export function supplierGetSingleIntegration(
  id,
) {
  return httpService.get(`/distributor/tray/solution-instances/${id}`);
}
