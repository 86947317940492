<script>
/**
 * @version 1.0.0
 * @since 3.16.0
 */
import { debounce } from '@/util/utils';
import { EzFormModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import EzTable from '@/components/ui/Table/EzTable';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import { mapActions, mapState, mapGetters } from 'vuex';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzBadge from '@/components/ui/Badge/EzBadge';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import EzInput from '@/components/ui/Input/EzInput';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzFilterList from '@/components/ui/FilterList/EzFilterList';
import { supplierPushProducts } from '@/api/endpoints/supplier-tray';

export default {
  name: 'PushProductsModal',
  components: {
    EzFormModal,
    EzButton,
    EzTable,
    EzCheckbox,
    EzEntityInfo,
    EzLoadMore,
    EzBadge,
    EzInput,
    EzSpinner,
    EzFilterList,
  },
  data() {
    return {
      products: [],
      meta: {},
      state: SELECT_DESELECT_ALL.deselectedAll,
      filters: {
        term: null,
      },
      touchedProducts: [],
      checked: [],
      unchecked: [],
      totalProducts: 0,
    };
  },
  computed: {
    ...mapState('entities/distributors', [
      'eCommerceIntegration',
    ]),
    isLoadingMore() {
      return this.isSomeLoading([LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS]);
    },
    platformName() {
      return this.eCommerceIntegration?.providerName || 'Shopify';
    },
    venueId() {
      return this.eCommerceIntegration?.config?.venueId || '';
    },
    columns() {
      return ['product'];
    },
    areAllSelected() {
      return this.state === SELECT_DESELECT_ALL.selectedAll;
    },
    defaultChecked() {
      return this.state === SELECT_DESELECT_ALL.deselectedAll;
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchVenueVisibleProducts',
    ]),
    ...mapGetters('loading', ['isSomeLoading']),
    fetchVisibleProducts() {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...(this.filters.term ? { term: this.filters.term } : {}),
        hidden: 'true',
        limit: '20',
      };
      return this.distributorFetchVenueVisibleProducts({
        venueId: this.venueId,
        query: queryParams,
      });
    },
    productsCount() {
      if (!this.areAllSelected) return this.checked.length;
      return this.totalProducts - this.unchecked.length;
    },
    syncStates() {
      if (this.areAllSelected) {
        this.setState(this.touchedProducts, false);
      } else {
        this.setState(this.touchedProducts, true);
      }
    },
    setState(products, val) {
      products.forEach((pr) => {
        const prx = this.products.find(p => p.id === pr);
        if (prx) {
          prx.checked = val;
        }
      });
    },
    async refresh() {
      this.meta = {};
      const { data: { data, meta } } = await this.fetchVisibleProducts();
      this.products = data.map(pr => ({ ...pr, checked: !this.defaultChecked }));
      this.meta = meta;
      if (!this.totalProducts) this.totalProducts = meta.totalCount;
      this.syncStates();
    },
    async loadMore() {
      const { data: { data, meta } } = await this.fetchVisibleProducts();
      this.products = [
        ...this.products,
        ...data.map(pr => ({ ...pr, checked: !this.defaultChecked })),
      ];
      this.meta = meta;
      this.syncStates();
    },
    close() {
      this.$refs.productsModal.close();
    },
    open() {
      this.touchedProducts = [];
      this.$refs.productsModal.open();
    },
    isChecked(product) {
      const idx = this.products.findIndex(pr => pr.id === product.id);
      return this.products[idx].checked;
    },
    async pushProducts() {
      const uncheckedMapped = this.unchecked.map(pr => pr.id);
      const checkedMapped = this.checked.map(pr => pr.id);
      const data = {
        state: this.state,
        items: this.areAllSelected ? uncheckedMapped : checkedMapped,
      };
      await supplierPushProducts(this.eCommerceIntegration.instanceId, data);
      this.$emit('success');
      this.close();
    },
    onCheckboxChange(product, checked) {
      if (this.touchedProducts.find(pr => pr === product.id)) {
        this.touchedProducts = this.touchedProducts.filter(pr => pr !== product.id);
      } else {
        this.touchedProducts.push(Number(product.id));
      }
      const idx = this.products.findIndex(pr => pr.id === product.id);
      this.products[idx].checked = checked;

      if (this.areAllSelected) {
        this.checked = [];
        const uncheckedIdx = this.unchecked.find(p => p.id === product.id);

        if (!checked) this.unchecked.push(this.products[idx]);
        else this.unchecked.splice(uncheckedIdx, 1);
      } else {
        this.unchecked = [];
        const checkedIdx = this.checked.find(p => p.id === product.id);

        if (checked) this.checked.push(this.products[idx]);
        else this.checked.splice(checkedIdx, 1);
      }
    },
    selectDeselectAll(ev) {
      this.touchedProducts = [];
      this.state = ev ? SELECT_DESELECT_ALL.selectedAll : SELECT_DESELECT_ALL.deselectedAll;
      this.products = this.products.map(pr => ({ ...pr, checked: !this.defaultChecked }));

      if (ev) this.checked = [];
      else this.unchecked = [];
    },
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: `${this.oezPlatformName} Products`,
              indeterminate:
                (this.areAllSelected && !!this.unchecked.length)
                || (!this.areAllSelected && !!this.checked.length),
              checked: this.areAllSelected,
            },
            on: {
              change: (event) => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    updateFilters: debounce(function deb(filterName, event) {
      if (filterName === 'search') {
        if (this.filters.term === event) return;
        this.filters = {
          ...this.filters,
          term: event,
        };
      }
      this.refresh();
    }, 300),
    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
      this.refresh();
    },
    async onCloseModal() {
      this.products = [];
      this.checked = [];
      this.unchecked = [];
      this.touchedProducts = [];
      await this.$nextTick();
      this.resetFilters();
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<template>
  <ez-form-modal
    size="xl"
    ref="productsModal"
    @close="onCloseModal"
  >
    <template #title>
      Push Products
    </template>
    <template #content>
      <p class="subtitle">Select the products you would like to push to {{ platformName }}</p>

      <ez-filter-list
        :filters="filters"
        @resetFilter="resetFilters"
        @filterUpdated="updateFilters"
        class="mb-12 mt-12">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search width-100"
          :value="filters.term"
          :placeholder="`Select the products you would like to create in ${platformName}`">
          <template #suffix>
            <font-awesome-icon icon="search"/>
          </template>
        </ez-input>
      </ez-filter-list>

      <div class="table-wrapper">
        <div class="table-wrapper__inner">
          <ez-table
            class="mt-8"
            :data="products"
            :headers="{
              product: nameCheckbox,
            }"
            :columns="columns"
            @rowClick="onCheckboxChange($event, !isChecked($event))"
          >
            <template #cell-product="{ row }">
              <div class="cell-product-container">
                <div @click.stop>
                  <ez-checkbox
                    class="cursor-pointer mr-8"
                    :key="row.id"
                    :checked="row.checked"
                    @change="onCheckboxChange(row, $event)"
                  />
                </div>
                <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
                  <div class="product-info" :title="row.name">
                    <span>{{ row.name }}</span>
                    <span class="product-info-secondary">
                    {{ row.sku }}
                  </span>
                  </div>
                </ez-entity-info>
              </div>
            </template>
            <template #cell-sku="{ row }">
              {{ row.sku}}
            </template>
          </ez-table>

          <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="loadMore"/>

          <div v-if="meta.nextId && isLoadingMore" class="u-text-center mt-12">
            <ez-spinner />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="products-selected">
        <div v-if="!productsCount()">No products selected</div>
        <div v-else>
          Products selected
          <ez-badge
            class="products-selected--counter"
            type="blue"
            :count="productsCount()"
          />
        </div>
      </div>
      <div>
        <ez-button
          type="link"
          formType="button"
          @click="close">Cancel</ez-button>
        <ez-button
          formType="button"
          @click="pushProducts"
          :disabled="!productsCount()"
        >
          Push Products
        </ez-button>
      </div>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.subtitle {
  margin: 0;
}

.cell-product-container {
  @extend %flex-center;
  justify-content: flex-start;
}

.product-info {
  display: flex;
  flex-direction: column;

  .product-info-secondary {
    display: flex;
    align-items: center;
    @include font-size(12px, 14px);
    font-weight: 500;
    color: $color-gray-6C;
  }
}

.products-selected {
  color: $color-gray-6C;
  @include font-size(14px, 20px);
}

:deep() .modal {
  height: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 810px;
  }

  &__text {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .table-wrapper {
      position: relative;
      flex: 1 0 auto;

      &__inner {
        position: absolute;
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }

  &__footer {
    @extend %flex-space;
  }
}
</style>
