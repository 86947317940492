<script>
/**
 * E-Commerce Not Connected Screen
 * @version 1.0.0
 * @since 3.16.0
 */

import EmptyState from '@/views/common/empty-state';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EzInput from '@/components/ui/Input/EzInput';
import EzForm from '@/components/ui/Form/EzForm';
import { openConfigWindow } from '@/util/configWindow';
import bus from '@/util/trayBus';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { INTEGRATIONS_TYPES, LOADING_KEY } from '@/util/constants';
import { supplierGetConfigWizardURL } from '@/api/endpoints/supplier-tray';
import flash from '@/components/ui/FlashMessage';
import installStep from '@/views/platform/distributor/e-commerce-integrations/installStep';

export default {
  components: {
    EmptyState,
    EzButton,
    EzConfirmationModal,
    EzInput,
    EzForm,
    EzLoader,
    installStep,
  },
  data() {
    return {
      isNameValid: false,
      formKey: 'createSolution',
      loadingKeyForm: 'createSolutionLoading',
      queryData: null,
    };
  },
  computed: {
    ...mapState('entities/distributors', [
      'eCommerceIntegration',
    ]),
    ...mapState('entities/users', [
      'loggedUser',
    ]),
    ...mapGetters('entities/distributors', [
      'isSelectedEcommerceNotEnabled',
      'isSelectedEcommerceConnected',
      'isSelectedEcommerceNotConnected',
      'isSelectedEcommerceActionNeeded',
    ]),
    hasQueryData() {
      const requiredParams = ['state'];
      const providedParams = Object.keys(this.queryData || {});
      return requiredParams.every(p => providedParams.includes(p));
    },
    isTrayDefault() {
      return this.eCommerceIntegration.type === INTEGRATIONS_TYPES.default;
    },
    additionalFormData() {
      return { solutionId: this.eCommerceIntegration.id, type: this.eCommerceIntegration.type };
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_ENABLE_INTEGRATIONS,
      ]);
    },
    platformName() {
      return this.eCommerceIntegration?.providerName || 'Shopify';
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    hasIntegrationData() {
      return !!Object.keys(this.eCommerceIntegration).length;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor']),
    ...mapActions('entities/distributors', [
      'distributorEnableConnectedIntegrations',
      'distributorFetchConnectedIntegrations',
    ]),
    ...mapMutations('entities/distributors', [
      'SET_SELECTED_INTEGRATION',
      'UPDATE_SELECTED_INTEGRATION',
    ]),
    confirmConnection() {
      this.$refs.form.onSubmit();
    },
    redirectToSettings() {
      if (this.isSelectedEcommerceConnected && !this.isTrayDefault) {
        this.$router.replace({
          name: 'distributor-e-commerce-products',
        });
      }
    },
    onSubmitSuccess({ data }) {
      const configWindow = openConfigWindow();
      configWindow.location = data.data.configUrl;
      this.UPDATE_SELECTED_INTEGRATION(data.data);
    },
    onSubmitError(err) {
      // eslint-disable-next-line no-console
      console.error(err);
    },
    async onFinishedIntegration() {
      if (this.hasQueryData) {
        await this.$router.replace({ query: null });
        this.queryData = null;
      }

      if (this.isSelectedEcommerceActionNeeded) {
        await this.enableIntegration();
        return;
      }
      if (!this.hasQueryData) {
        this.$refs.connectModal.close();
      }
      await this.enableIntegration();
    },
    isNotFinished() {
      if (!this.hasQueryData) {
        this.$refs.connectModal.close();
      }
      this.$router.push({ name: 'distributor-integrations' });
    },
    async update() {
      const { data } = await supplierGetConfigWizardURL(this.eCommerceIntegration.instanceId);
      const configWindow = openConfigWindow();
      configWindow.location = data.data;
    },
    async enableIntegration() {
      const { data: { data } } = await this.distributorEnableConnectedIntegrations({
        solutionId: this.eCommerceIntegration.instanceId,
      });
      this.SET_SELECTED_INTEGRATION(data);
      await flash.success({
        title: this.$t('integrations.tray.flashSuccessTitle'),
        message: this.$t('integrations.tray.flashSuccessMessage'),
        delay: 6000,
      });
      await this.redirectToSettings();
    },
    onNameInputChange(val) {
      this.isNameValid = !!val.trim();
    },
    goToExternalUrl() {
      window.open(this.eCommerceIntegration.externalUrl, '_blank');
    },
  },
  async created() {
    const { query } = this.$route;
    this.queryData = query;
    if (!this.hasQueryData) {
      await this.redirectToSettings();
    }
  },
  mounted() {
    bus.$on('finished', this.onFinishedIntegration);
    bus.$on('notFinished', this.isNotFinished);
  },
  beforeDestroy() {
    bus.$off('finished', this.onFinishedIntegration);
    bus.$off('notFinished', this.isNotFinished);
  },
};
</script>
<template>
  <div :class="['not-connected', { 'not-connected__redirect': hasQueryData }]">
    <empty-state v-if="!hasQueryData">
      <template #badge>
        <div class="empty-image-wrapper">
          <img class="empty-image" src="@/assets/no-standing-scheduled-state.svg" alt="" width="160" height="138">
          <img class="empty-image-logo" :src="eCommerceIntegration.icon" width="32" height="32" alt=""/>
        </div>
      </template>
      <template #title>
        <span v-if="isSelectedEcommerceConnected">Your {{ platformName }} integration is all set</span>
        <span v-if="isSelectedEcommerceNotEnabled">Enable your {{ platformName }} account</span>
        <span v-if="isSelectedEcommerceNotConnected">Connect your {{ platformName }} account</span>
      </template>
      <template #info>
        <p v-if="isSelectedEcommerceConnected">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text.
        </p>
        <p v-else>
          Only one step is left! Enable your {{ platformName }} account to be able to sync your Products and
          Contacts on {{ platformName }} with your {{ oezPlatformName }} account.
        </p>
      </template>

      <div class="mt-16">
        <ez-button @click="update" class="action" key="enableEcommerce" v-if="isSelectedEcommerceNotEnabled">
          <span>{{ $t('global.actions.enable') }}</span>
          <font-awesome-icon icon="link"/>
        </ez-button>
        <div v-if="isSelectedEcommerceNotConnected">
          <ez-button @click="goToExternalUrl" class="action" key="connectEcommerce" v-if="eCommerceIntegration.externalUrl">
            <span>{{ $t('global.actions.connect') }}</span>
            <font-awesome-icon icon="link"/>
          </ez-button>
          <ez-button v-else class="action" key="connectEcommerce" v-open="'connectModal'">
            <span>{{ $t('global.actions.connect') }}</span>
            <font-awesome-icon icon="link"/>
          </ez-button>
        </div>
      </div>
    </empty-state>

    <install-step :query-data="queryData" v-else />

    <ez-confirmation-modal ref="connectModal" icon="question">
      <template #title>Name Your {{ platformName }} Account</template>
      <template #content>
        <div class="xero-description">
          Add integration name in order to enter the configuration wizard.
        </div>
        <hr>
        <ez-form
          :formKey="formKey"
          :loading-key="loadingKeyForm"
          action="/distributor/tray/solution-instances"
          method="post"
          :additional-data="additionalFormData"
          @success="onSubmitSuccess"
          @error="onSubmitError"
          ref="form"
        >
          <ez-input
            ref="nameInput"
            class="integration-name mt-12"
            label="Account Name"
            :formKey="formKey"
            name="name"
            :placeholder="`${platformName} - ${loggedUser.distributor.name}`"
            @onChange="onNameInputChange"
          />
        </ez-form>
      </template>
      <template #footer>
        <ez-button type="link" v-close="'connectModal'">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button class="ml-12" @click="confirmConnection" :disabled="!isNameValid">
          {{ $t('global.actions.saveAndContinue') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
.not-connected {
  padding-top: 80px;

  &__redirect {
    padding: 0;
  }
}
:deep() .ez-empty-state {
  p {
    @include font-size(12px, 18px);
  }
  h3 {
    @include font-size(18px, 24px);
    font-weight: bold;
  }
}

.action {
  :deep() {
    svg {
      margin: 0 0 0 8px;
    }
  }
}

.empty-image-wrapper {
  position: relative;
  .empty-image {
    position: relative;
  }
  .empty-image-logo {
    position: absolute;
    right: 22px;
    bottom: 0;
    width: 65px;
    height: 65px;
  }
}
</style>
