<script>
/**
 * Products
 * @version 1.0.0
 * @since 3.16.0
 */
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  supplierGetProducts,
  supplierGetSingleIntegration,
  supplierMapProducts,
} from '@/api/endpoints/supplier-tray';
import { ECOMMERCE_ACTIONS, LOADING_KEY } from '@/util/constants';
import { clone } from '@/util/utils';
import flash from '@/components/ui/FlashMessage';
import EzAlert from '@/components/ui/Alert/EzAlert';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzButton from '@/components/ui/Button/EzButton';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EmptyState from '@/views/common/empty-state';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzButtonGroup from '@/components/ui/Button/EzButtonGroup';
import { EzConfirmationModal } from '@/components/ui/Modal';
import PushProductsModal from '@/views/platform/distributor/e-commerce-integrations/PushProductsModal';
import MapProductsModal from './MapProductsModal.vue';

export default {
  name: 'Products',
  components: {
    EzAlert,
    EzTable,
    EzLoader,
    EzButton,
    EzSpinner,
    EmptyState,
    EzLoadMore,
    EzEntityInfo,
    EzButtonGroup,
    MapProductsModal,
    EzConfirmationModal,
    PushProductsModal,
  },
  data() {
    return {
      meta: {},
      products: [],
      initialProducts: [],
      pollingInterval: null,
      pushIntermediate: false,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['eCommerceIntegration']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    platformName() {
      return this.eCommerceIntegration?.providerName || 'Shopify';
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.SUPPLIER_GET_PRODUCTS,
        LOADING_KEY.SUPPLIER_MAP_PRODUCTS,
        LOADING_KEY.SUPPLIER_PUSH_PRODUCTS,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.LOAD_MORE_PRODUCTS);
    },
    headers() {
      return {
        shopifyProducts: () => `${this.platformName} Products`,
        mappedProducts: () => `Mapped ${this.oezPlatformName} Products`,
      };
    },
    columns() {
      return ['shopifyProducts', 'mappedProducts', 'action'];
    },
    haveUnmappedProducts() {
      return this.initialProducts.some(p => !p.mappedProducts.length);
    },
    hasProducts() {
      return this.products.length;
    },
    isPushProducts() {
      return this.eCommerceIntegration?.config?.pushInProgress || this.pushIntermediate;
    },
    isSyncProducts() {
      return this.eCommerceIntegration?.config?.productSyncInProgress;
    },
    hasMadeChanges() {
      return !!this.getUpdatedProductsFrom(this.products).length;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', ['UPDATE_SELECTED_INTEGRATION']),
    openProductMapModal(row) {
      this.$refs.mapProductModal.open(row);
    },
    openConfirmation() {
      this.$refs.confirmationModal.open();
    },
    closeConfirmation() {
      this.$refs.confirmationModal.close();
    },
    submitProductMapping(product) {
      const idx = this.products.findIndex(p => p.id === product.id);
      this.products[idx].mappedProducts = [...product.mappedProducts];
    },
    async confirmMappedProducts() {
      const updatedProducts = this.getUpdatedProductsFrom(this.products);
      const products = this.formatUpdatedProducts(updatedProducts);

      try {
        await supplierMapProducts(this.eCommerceIntegration.instanceId, { products });
        this.initialProducts = clone(this.products);

        this.UPDATE_SELECTED_INTEGRATION({ prevAction: ECOMMERCE_ACTIONS.mapProducts });
        await this.updateConfig();
        this.initStatusPolling();
      } catch (err) {
        flash.error({
          title: 'Something went wrong!',
          message: 'We are not able to process your request at the moment.',
        });
      }
      this.closeConfirmation();
    },
    pushChanges() {
      this.$refs.pushProductsModal.open();
    },
    isMapped(row) {
      return !!row.mappedProducts.length;
    },
    async getProductsData() {
      const { data } = await supplierGetProducts(this.eCommerceIntegration.instanceId);
      this.products = [...clone(data.data)];
      this.initialProducts = [...clone(data.data)];
      this.meta = data.meta;
    },
    compareProductArrays(a, b) {
      const idsA = a.map(p => p.product.id).filter(Boolean);
      const idsB = b.map(p => p.product.id).filter(Boolean);
      const idsEq = idsA.sort().join('') === idsB.sort().join('');

      const quantsA = a.map(p => p.quantity).filter(Boolean);
      const quantsB = b.map(p => p.quantity).filter(Boolean);
      const quantsEq = quantsA.sort().join('') === quantsB.sort().join('');

      return idsEq && quantsEq;
    },
    getUpdatedProductsFrom(products) {
      const updatedProducts = products.map((prod) => {
        const initProduct = this.initialProducts.find(p => p.id === prod.id);
        const initMappedProducts = initProduct.mappedProducts;
        const sameAsInitial = this.compareProductArrays(prod.mappedProducts, initMappedProducts);

        return sameAsInitial ? false : prod;
      });

      return updatedProducts.filter(Boolean);
    },
    formatUpdatedProducts(products) {
      return products.map(({ id, mappedProducts }) => ({
        providerId: id,
        mappedProducts: this.formatMapped(mappedProducts),
      }));
    },
    formatMapped(products) {
      return products.map(p => ({ id: p.product.id, quantity: +p.quantity }));
    },
    async refresh() {
      const { data } = await supplierGetProducts(
        this.eCommerceIntegration.instanceId,
        LOADING_KEY.LOAD_MORE_PRODUCTS,
        { nextId: this.meta.nextId },
      );
      this.products = [...this.products, ...clone(data.data)];
      this.initialProducts = [...this.initialProducts, ...clone(data.data)];
      this.meta = data.meta;
    },
    onLoadMore() {
      if (this.meta.nextId) this.refresh();
    },
    async updateConfig() {
      const {
        data: { data },
      } = await supplierGetSingleIntegration(this.eCommerceIntegration.instanceId);
      this.UPDATE_SELECTED_INTEGRATION(data);
    },
    hasMadeChangesToProduct(product) {
      return !!this.getUpdatedProductsFrom([product]).length;
    },
    productActionText(row) {
      if (this.hasMadeChangesToProduct(row)) return 'Mapping Edited';
      if (this.isMapped(row)) return 'Edit Mapping';
      return 'Add Mapping';
    },
    initStatusPolling() {
      if (this.pollingInterval) return;

      this.pollingInterval = setInterval(async () => {
        if (!this.isPushProducts && !this.isSyncProducts) {
          clearInterval(this.pollingInterval);
          this.pollingInterval = null;
        }
        await this.updateConfig();
        this.pushIntermediate = false;
      }, 15 * 1000);
    },
    async onProductsPush() {
      this.pushIntermediate = true;
      this.UPDATE_SELECTED_INTEGRATION({ prevAction: ECOMMERCE_ACTIONS.pushProducts });
      await this.updateConfig();
      this.initStatusPolling();
    },
  },
  created() {
    this.getProductsData();
    this.initStatusPolling();
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval);
    this.pollingInterval = null;
  },
  watch: {
    eCommerceIntegration: {
      deep: true,
      handler(val, oldVal) {
        const { prevAction } = this.eCommerceIntegration;
        const isPrevActionPush = prevAction === ECOMMERCE_ACTIONS.pushProducts;
        const isPrevActionMap = prevAction === ECOMMERCE_ACTIONS.mapProducts;

        const newPush = val.config?.pushInProgress;
        const oldPush = oldVal.config?.pushInProgress;

        const newMap = val.config?.productSyncInProgress;
        const oldMap = oldVal.config?.productSyncInProgress;

        if (!newPush && oldPush && isPrevActionPush) {
          flash.success({ title: `Products successfully pushed to ${this.platformName}.` });
          this.getProductsData();
          this.UPDATE_SELECTED_INTEGRATION({ prevAction: null });
        }

        if (!newMap && oldMap && isPrevActionMap) {
          flash.success({
            title: 'Products successfully mapped!',
            message: `${this.platformName} products are now synced with ${this.oezPlatformName}.`,
          });
          this.UPDATE_SELECTED_INTEGRATION({ prevAction: null });
        }
      },
    },
  },
};
</script>

<template>
  <div>
    <ez-alert v-if="haveUnmappedProducts" variant="disclaimer" custom-class="not-mapped-alert mt-0">
      <template #icon><font-awesome-icon icon="exclamation-circle"/></template>
      <span>
        There are unmapped {{ platformName }} products. Please map them to ensure proper syncing
        with {{ oezPlatformName }}.
      </span>
    </ez-alert>

    <div class="header mb-32">
      <span class="header__info-text">
        Map your {{ platformName }} products to those from {{ oezPlatformName }}. Add multiple products or vary
        the quantity to create bundles or packs.
      </span>
      <ez-button-group v-if="hasProducts">
        <ez-button
          class="header__cta push-products-btn"
          type="secondary"
          @click="pushChanges"
          :disabled="isPushProducts"
        >
          <span v-if="isPushProducts" class="u-flex-h-center loader mr-8">
            <img src="@/assets/icons/loader.svg" alt="" />
          </span>
          {{ isPushProducts ? 'Pushing Products' : 'Push Products' }}
        </ez-button>
        <ez-button
          class="header__cta save-changes-btn"
          @click="openConfirmation"
          :disabled="isSyncProducts || !hasMadeChanges"
        >
          <span v-if="isSyncProducts" class="u-flex-h-center loader mr-8">
            <img src="@/assets/icons/loader.svg" alt="" />
          </span>
          {{ isSyncProducts ? 'Saving Changes' : 'Save Changes' }}
        </ez-button>
      </ez-button-group>
    </div>

    <div>
      <ez-table
        v-if="hasProducts"
        class="sync-table"
        :disable-hover="true"
        :data="products"
        :columns="columns"
        :headers="headers"
        :columnProps="{
          shopifyProducts: { class: 'name-cell' },
          mappedProducts: { class: 'mappedProducts-cell' },
          action: { class: 'action-cell' },
        }"
      >
        <template #cell-shopifyProducts="{ row }">
          <ez-entity-info
            :imgUrl="row.image"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="3px"
          >
            <span class="entity-info__name">{{ row.name }}</span>
            <span class="entity-info__sku">{{ row.sku }}</span>
          </ez-entity-info>
        </template>
        <template #cell-mappedProducts="{ row }">
          <span v-if="!isMapped(row)" class="no-products">No mapped products</span>
          <ez-entity-info
            v-else
            v-for="{ product } in row.mappedProducts"
            :key="product.id"
            v-tooltip="{
              content: product.name,
              placement: 'top',
              classes: ['tooltip--reset-margin'],
            }"
            :imgUrl="product.image"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="3px"
          />
        </template>
        <template #cell-action="{ row }">
          <ez-button
            :class="[
              'product-action',
              { 'product-action--unmapped': !hasMadeChangesToProduct(row) && !isMapped(row) },
            ]"
            type="secondary"
            @click="openProductMapModal(row)"
          >
            {{ productActionText(row) }}
          </ez-button>
        </template>
      </ez-table>

      <empty-state v-if="!hasProducts && !isLoading">
        <template #badge><img src="@/assets/no-mapped-products.svg" alt=""/></template>
        <template #title>You have no products to be shown</template>
        <template #info>
          <div class="u-flex-column">
            In order to start selling on {{ platformName }}, we have the option to push the {{ oezPlatformName }}
            products to {{ platformName }}.
            <ez-button
              class="mt-16 start-selling-btn"
              type="primary"
              @click="pushChanges"
              :disabled="isPushProducts"
            >
              <span v-if="isPushProducts" class="u-flex-h-center loader mr-8">
                <img src="@/assets/icons/loader.svg" alt="" class="loader-img" />
              </span>
              {{ isPushProducts ? 'Pushing Products' : 'Push Products' }}
            </ez-button>
          </div>
        </template>
      </empty-state>
    </div>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <map-products-modal ref="mapProductModal" @submit="submitProductMapping" />

    <ez-confirmation-modal
      innerClasses="confirmation-modal"
      ref="confirmationModal"
      icon="question"
    >
      <template #title>Save Product Mapping Changes?</template>
      <template #content>
        Are you sure you want to save the latest changes to products mapping?
      </template>
      <template #footer>
        <ez-button-group>
          <ez-button type="link" @click="closeConfirmation">Cancel</ez-button>
          <ez-button @click="confirmMappedProducts">Save</ez-button>
        </ez-button-group>
      </template>
    </ez-confirmation-modal>
    <push-products-modal @success="onProductsPush" ref="pushProductsModal" />
  </div>
</template>

<style lang="scss" scoped>
.not-mapped-alert {
  justify-content: start;
}

.header {
  display: flex;
  justify-content: space-between;

  &__info-text {
    @include font-size(14px, 20px);
    width: 400px;
    font-weight: 500;
    color: $color-gray-6C;
  }
}

.save-changes-btn,
.start-selling-btn {
  :deep() span {
    display: flex;
  }
}

.push-products-btn {
  :deep() span {
    display: flex;
  }

  &:disabled {
    box-shadow: none;
    background-color: #f5f6fa;
    opacity: 0.6;
    color: $color-gray-6C;
    cursor: not-allowed;
  }
}

.loader {
  animation: spin 1.1s infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.sync-table tbody tr td {
  overflow: visible;
}

:deep() .ez-empty-state {
  margin-top: 120px;
  img:not(.loader-img) {
    width: 160px;
  }

  p {
    max-width: 400px;
  }
}

.name-cell {
  :deep() .entity-info {
    align-items: center;

    &__text {
      flex-direction: column;
      align-items: start;
      overflow-y: hidden;
    }

    &__name {
      @include font-size(14px, 20px);
      font-weight: 600;
      color: $color-gray-25;
    }

    &__sku {
      @include font-size(12px, 14px);
      font-weight: 500;
      color: $color-gray-6C;
    }
  }
}

.mappedProducts-cell {
  width: 22rem;

  .no-products {
    @include font-size(14px, 20px);
    font-weight: 500;
    color: $color-gray-6C;
  }

  :deep() .entity-info {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.action-cell {
  width: 10rem;

  .product-action {
    &--unmapped {
      color: $color-primary-blue;
    }
  }
}
</style>
