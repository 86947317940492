<template>
  <div class="ez-number-input">
    <div class="input-group">
      <label v-if="label" class="input-group__label" :for="id">{{ label }}</label>
      <div
        :class="['input-group__input', { 'input-group__input--disabled': disabled }]"
        v-tooltip="{
          placement: 'top',
          content: formattedErrorTooltip,
          show: errorTooltipShow,
          trigger: 'manual',
          classes: ['tooltip--reset-margin', 'tooltip--danger'],
        }"
      >
        <button
          :data-cy="`${dataCy}-decreaseQuantity`"
          type="button"
          :disabled="minusDisabled || disabled"
          @click="onMinusClick"
          v-tooltip="{
            placement: tooltipPlacement,
            content: !isDivisible ? 'Round down' : decreaseTooltipContent,
            classes: ['tooltip--reset-margin'],
          }"
        >
          <font-awesome-icon size="xs" icon="minus" />
        </button>
        <input
          v-if="!isFractional"
          :class="[{ 'input-group__input--invalid': inputInvalid }]"
          :id="id"
          :name="name"
          :placeholder="placeholder"
          @input="onChange"
          @keypress="keyPress"
          v-model.number="val"
          :disabled="disabled"
          :required="required"
          type="number"
          :data-cy="dataCy"
        />
        <ez-mask-input
          v-else
          :form-key="formKey"
          :name="name"
          :class="['mask-input', { 'mask-input--invalid': inputInvalid }]"
          :value="val"
          :disabled="disabled"
          :precision="4"
          :price-prefix="false"
          type="inline"
          @input="onMaskChange"
          :allow-negative-value="allowNegativeValue"
          :data-cy="dataCy"
        />
        <div class="input-group__input__suffix" v-if="hasSuffixSlot">
          <slot name="suffix"></slot>
        </div>
        <button
          :data-cy="`${dataCy}-increaseQuantity`"
          type="button"
          :disabled="plusDisabled || disabled"
          @click="onPlusClick"
          v-tooltip="{
            placement: tooltipPlacement,
            content: !isDivisible ? 'Round up' : increaseTooltipContent,
            classes: ['tooltip--reset-margin'],
          }"
        >
          <font-awesome-icon size="xs" icon="plus" />
        </button>
      </div>
      <div v-if="hasServerErrorMessage" class="input-group__error">{{ serverError.message }}</div>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapMutations } from 'vuex';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import { debounce, isIntegerRoundedNumber } from '@/util/utils';

export default {
  name: 'eznumberinput',
  components: {
    EzMaskInput,
  },
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: true,
      type: String,
    },
    /**
     * Input name
     */
    name: {
      required: true,
      type: String,
    },
    /**
     * Input value
     */
    value: {
      required: false,
      default: 0,
      type: [String, Number],
    },
    /**
     * Min value
     */
    minValue: {
      required: false,
      default: Number.MIN_SAFE_INTEGER,
      type: Number,
    },
    /**
     * Max value
     */
    maxValue: {
      required: false,
      default: Number.MAX_SAFE_INTEGER,
      type: Number,
    },
    /**
     * Label that is above the input.
     */
    label: {
      required: false,
      type: String,
      default: '',
    },
    /**
     * Placeholder text inside the input.
     */
    placeholder: {
      required: false,
      type: String,
    },
    /**
     * If the input is disable or not.
     */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is required or not.
     */
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is readonly or not.
     */
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If set to true bottom border will be red.
     */
    isInvalid: {
      required: false,
      type: Boolean,
      default: false,
    },
    isFractional: {
      type: Boolean,
      required: false,
      default: false,
    },
    increment: {
      type: Number,
      required: false,
      default: 1,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
    tooltipPlacement: {
      required: false,
      type: String,
      default: 'top',
    },
    hideTooltip: {
      required: false,
      type: Boolean,
      default: false,
    },
    allowNegativeValue: {
      required: false,
      type: Boolean,
      default: true,
    },
    showOutOfBoundsError: {
      required: false,
      type: Boolean,
      default: false,
    },
    invalidIfOutOfBounds: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * @typedef   {object}  customTooltipError
     * @property  {string}  content
     * @property  {boolean} check
     */
    customTooltipError: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      id: null,
      val: 0,
      isDivisible: true,
      remainder: 0,
      outOfBounds: false,
    };
  },
  computed: {
    serverError() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    minusDisabled() {
      return this.val <= this.minValue;
    },
    plusDisabled() {
      return this.val >= this.maxValue;
    },
    hasServerErrorMessage() {
      return this.serverError
          && typeof this.serverError === 'object'
          && Object.prototype.hasOwnProperty.call(this.serverError, 'message');
    },
    hasSuffixSlot() {
      return !!this.$slots.suffix;
    },
    increaseTooltipContent() {
      return `Increase by ${this.increment}`;
    },
    decreaseTooltipContent() {
      return `Decrease by ${this.increment}`;
    },
    invalidQuantityTooltip() {
      return 'Invalid quantity added. The added quantity must be divisible with the defined quantity increment.';
    },
    outOfBoundsQuantityTooltip() {
      const min = this.allowNegativeValue ? this.minValue : 0;
      const max = this.maxValue;
      return `Invalid quantity added. The added quantity must be within ${min}-${max} range.`;
    },
    hasOutOfBoundsError() {
      return this.showOutOfBoundsError && this.outOfBounds;
    },
    hasCustomError() {
      if (!this.customTooltipError) return false;
      return this.customTooltipError.check;
    },
    errorTooltipContent() {
      if (this.hasCustomError) return this.customTooltipError.content;
      if (this.hasOutOfBoundsError) return this.outOfBoundsQuantityTooltip;
      return this.invalidQuantityTooltip;
    },
    formattedErrorTooltip() {
      if (!this.dataCy) return this.errorTooltipContent;
      return `<span data-cy="${this.dataCy}-error">${this.errorTooltipContent}</span>`;
    },
    errorTooltipShow() {
      if (this.hideTooltip) return false;
      return !this.isDivisible || this.hasOutOfBoundsError || this.hasCustomError;
    },
    isRemainder() {
      return this.remainder > 0;
    },
    inputInvalid() {
      return this.isInvalid || !this.isDivisible || (this.invalidIfOutOfBounds && this.outOfBounds);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.val = newVal;
        if (!this.isFractional) this.onChange();
        else this.onMaskChange(newVal);
      },
    },
    maxValue() {
      this.checkIsValid(this.val);
    },
    minValue() {
      this.checkIsValid(this.val);
    },
  },
  methods: {
    ...mapMutations('errors', [
      'CLEAR_ERROR',
    ]),
    onMinusClick() {
      const value = !this.isFractional ? Number.parseInt(this.val, 10) : this.val;

      if (Number.isNaN(value)) {
        return;
      }

      if (value > this.minValue) {
        if (this.outOfBounds && value > this.maxValue) { // if value is over maxValue, set to maxValue
          this.val = this.maxValue;
        } else if (!this.isDivisible) { // if not divisible round down on first divisible number
          this.val = value - this.remainder;
        } else {
          this.val = value - this.increment;
        }
        /**
         * When input value is changed.
         * @event onChange
         * @type String
         */
        this.onChange();
        this.onMaskChange(this.val);
      }
    },
    onPlusClick() {
      const value = !this.isFractional ? Number.parseInt(this.val, 10) : this.val;

      if (Number.isNaN(value)) {
        return;
      }

      if (value < this.maxValue) {
        if (this.outOfBounds && value < this.minValue) { // if value is under minValue, set to minValue
          this.val = this.minValue;
        } else if (!this.isDivisible) { // if not divisible round up on first divisible number
          this.val = value - this.remainder + this.increment;
        } else {
          this.val = value + this.increment;
        }
        /**
         * When input value is changed.
         * @event onChange
         * @type String
         */
        this.onChange();
        this.onMaskChange(this.val);
      }
    },
    onChange: debounce(function deb() {
      if (this.hasServerErrorMessage) {
        this.CLEAR_ERROR({
          formKey: this.formKey,
          field: this.name,
        });
      }
      this.checkIsValid(this.val);
      if (!this.isDivisible) {
        return;
      }
      /**
       * When input value is changed.
       * @event onChange
       * @type String
       */
      this.$emit('onChange', this.val);
    }, 350),
    onMaskChange: debounce(function deb(val) {
      this.val = val;
      if (this.hasServerErrorMessage) {
        this.CLEAR_ERROR({
          formKey: this.formKey,
          field: this.name,
        });
      }
      this.checkIsValid(this.val);
      if (!this.isDivisible) {
        return;
      }

      /**
       * When input value is changed.
       * @event onChange
       * @type String
       */
      this.$emit('onChange', this.val);
    }, 350),
    checkIsValid(val) {
      this.remainder = Number(val) % this.increment;
      this.isDivisible = isIntegerRoundedNumber(Number(val), this.increment);

      const over = val > this.maxValue;
      const under = val < (!this.allowNegativeValue ? 0 : this.minValue);
      this.outOfBounds = over || under;
      this.$emit('invalid', !this.isDivisible || this.outOfBounds);
    },
    keyPress(e) {
      const keyCode = (e.keyCode ? e.keyCode : e.which);

      if (keyCode < 48 || keyCode > 57) {
        e.preventDefault();
      }
    },
    async clearErrors() {
      await this.$nextTick();
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
  },
  mounted() {
    this.id = uuid();
  },
};
</script>

<style scoped lang="scss">
$input-height: 42px;
$input-border-color: #DEE1E4;
$input-button-bg-color: #F5F6FA;

.ez-number-input {
  @include font-size(14px);
  .input-group {
    &__label {
      display: block;
      margin-bottom: 6px;
      color: $label-color;
      @include font-size($label-font-size, $label-line-height);
      font-weight: 500;
      text-transform: capitalize;
    }
    &__input {
      display: flex;
      position: relative;

      &--disabled {
        opacity: 0.5;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %button-reset;
        flex-shrink: 0;
        @include size(24px);
        background-color: $input-button-bg-color;
        border-radius: 50%;

        &:hover {
          cursor: pointer;
          background-color: #E9EBF2;
        }
      }
      input[type=number] {
        @include font-size(14px);
        flex: 1 1 auto;
        margin: 0 4px;
        background: transparent;
        text-align: center;
        border: 0;
        border-bottom: 1px solid $input-border-color;
        min-width: 30px;
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:focus {
          outline: none;
          border-bottom: 1px solid #4D7CFE;
        }
      }
      &__suffix {
        position: absolute;
        text-align: center;
        z-index: 5;
        @include font-size(14px, 20px);
        color: $color-gray-6C;
        right: 28px;
      }

      .mask-input {
        :deep() {
          .mask-input {
            width: 100%;
            height: 100%;
            &__input {
              text-align: center;
              height: 100%;
              font-weight: normal;
              color: $color-gray-25;
              padding: 0;
              @include font-size(14px);
            }
          }
        }
      }
      .mask-input--invalid {
        :deep() {
          .mask-input {
            &__input {
              border-bottom: 1px solid $color-primary-red;
            }
          }
        }
      }
    }
    &__error {
      margin-top: 0.5rem;
      color: $input-border-error-color;
      @include font-size(12px);
    }
  }
}

.input-group__input--invalid {
  border-bottom: 1px solid $color-primary-red !important;
}
</style>
