<script>
/**
 * Warehouses
 * @version 1.0.0
 * @since 3.16.0
 */

import { mapState, mapGetters, mapMutations } from 'vuex';
import {
  supplierGetSingleIntegration,
  supplierGetWarehouses,
  supplierMapWarehouses,
} from '@/api/endpoints/supplier-tray';
import { ECOMMERCE_ACTIONS, LOADING_KEY } from '@/util/constants';
import { clone } from '@/util/utils';
import flash from '@/components/ui/FlashMessage';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzButton from '@/components/ui/Button/EzButton';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzAlert from '@/components/ui/Alert/EzAlert';
import EzButtonGroup from '@/components/ui/Button/EzButtonGroup';
import { EzConfirmationModal } from '@/components/ui/Modal';

export default {
  name: 'Warehouses',
  components: {
    EzTable,
    VSelectSearch,
    EzButton,
    EzLoader,
    EzAlert,
    EzButtonGroup,
    EzConfirmationModal,
  },
  data() {
    return {
      warehouses: [],
      providerWarehouses: [],
      updatedWarehouses: [],
      initialProviders: {},
      pollingInterval: null,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['eCommerceIntegration']),
    ...mapGetters('loading', ['isSomeLoading']),
    platformName() {
      return this.eCommerceIntegration?.providerName || 'Shopify';
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.SUPPLIER_GET_WAREHOUSES,
        LOADING_KEY.SUPPLIER_MAP_WAREHOUSES,
      ]);
    },
    hasChanges() {
      return !!this.updatedWarehouses.length;
    },
    columns() {
      return ['warehouse', 'shopifyLocation'];
    },
    isSomeMapped() {
      return this.warehouses.some(wr => wr.providerId);
    },
    isSyncWarehouses() {
      return this.eCommerceIntegration?.config?.warehouseSyncInProgress;
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', ['UPDATE_SELECTED_INTEGRATION']),
    openConfirmation() {
      this.$refs.confirmationModal.open();
    },
    closeConfirmation() {
      this.$refs.confirmationModal.close();
    },
    updateWarehouse(selected, row) {
      const idx = this.warehouses.findIndex(({ id }) => id === row.id);
      if (idx < 0) throw new Error(`Warehouse with id ${row.id} not found.`);

      this.warehouses[idx].providerId = selected.id || null;
      const warehouse = this.warehouses[idx];

      const isInitialProvider = this.initialProviders[warehouse.id] === (selected.id || null);
      const changeIdx = this.updatedWarehouses.findIndex(({ id }) => id === warehouse.id);

      if (!isInitialProvider) {
        if (changeIdx < 0) this.updatedWarehouses.push(warehouse);
        else this.updatedWarehouses.splice(changeIdx, 1, warehouse);
      } else {
        this.updatedWarehouses.splice(changeIdx, 1);
      }
    },
    async mapWarehouses() {
      const warehouses = this.warehouses.map(({ id, providerId }) => ({ id, providerId }));

      try {
        await supplierMapWarehouses(this.eCommerceIntegration.instanceId, { warehouses });
        this.initialProviders = this.warehouses.reduce(
          (a, c) => ({ ...a, [c.id]: c.providerId }),
          {},
        );
        this.updatedWarehouses = [];
        this.UPDATE_SELECTED_INTEGRATION({ prevAction: ECOMMERCE_ACTIONS.mapWarehouses });
        await this.updateConfig();
        this.initStatusPolling();
      } catch (err) {
        flash.error({
          title: 'Something went wrong!',
          message: 'We are not able to process your request at the moment.',
        });
      }

      this.closeConfirmation();
    },
    async getWarehousesData() {
      const {
        data: { data },
      } = await supplierGetWarehouses(this.eCommerceIntegration.instanceId);

      this.warehouses = clone(data.warehouses);
      this.providerWarehouses = clone(data.providerWarehouses);
      this.initialProviders = data.warehouses.reduce(
        (a, c) => ({ ...a, [c.id]: c.providerId }),
        {},
      );
    },
    async updateConfig() {
      const {
        data: { data },
      } = await supplierGetSingleIntegration(this.eCommerceIntegration.instanceId);
      this.UPDATE_SELECTED_INTEGRATION(data);
    },
    initStatusPolling() {
      if (this.pollingInterval) return;

      this.pollingInterval = setInterval(async () => {
        if (!this.isSyncWarehouses) {
          clearInterval(this.pollingInterval);
          this.pollingInterval = null;
        }
        await this.updateConfig();
      }, 15 * 1000);
    },
  },
  created() {
    this.getWarehousesData();
    this.initStatusPolling();
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval);
    this.pollingInterval = null;
  },
  watch: {
    eCommerceIntegration: {
      deep: true,
      handler(val, oldVal) {
        const { prevAction } = this.eCommerceIntegration;
        const isPrevActionMap = prevAction === ECOMMERCE_ACTIONS.mapWarehouses;

        const newMap = val.config?.warehouseSyncInProgress;
        const oldMap = oldVal.config?.warehouseSyncInProgress;

        if (!newMap && oldMap && isPrevActionMap) {
          flash.success({
            title: 'Warehouses successfully mapped!',
            message: `All ${this.oezPlatformName} warehouses are synced with their ${this.platformName} inventory locations.`,
          });
          this.UPDATE_SELECTED_INTEGRATION({ prevAction: null });
        }
      },
    },
  },
};
</script>

<template>
  <div>
    <div class="header mb-32">
      <ez-alert v-if="!isSomeMapped" variant="disclaimer" custom-class="not-mapped-alert mt-0">
        <template #icon><font-awesome-icon icon="exclamation-circle"/></template>
        <span class="alert-text">
          There are Warehouses which locations are not mapped. Please map them to ensure proper
          syncing with {{ oezPlatformName }}.
        </span>
      </ez-alert>
      <div class="subheader">
        <span class="header__info-text">
          {{ platformName }} uses “Locations” for the places where you sell, ship, stock, or fulfils
          orders. {{ oezPlatformName}} uses Warehouses for where your inventory resides. Map the Warehouse from
          {{ oezPlatformName }} to the Location in {{ platformName }} to ensure that inventory levels are reported
          properly.
        </span>
        <ez-button
          class="header__update-cta save-changes-btn"
          @click="openConfirmation"
          :disabled="isSyncWarehouses || !hasChanges"
        >
          <span v-if="isSyncWarehouses" class="u-flex-h-center loader mr-8">
            <img src="@/assets/icons/loader.svg" alt="" />
          </span>
          {{ isSyncWarehouses ? 'Saving Changes' : 'Save Changes' }}
        </ez-button>
      </div>
    </div>
    <div>
      <ez-table
        v-if="warehouses.length"
        class="sync-table"
        :disable-hover="true"
        :data="warehouses"
        :columns="columns"
        :columnProps="{
          warehouse: { class: 'name-cell' },
          shopifyLocation: { class: 'location-cell' },
        }"
      >
        <template #cell-warehouse="{ row }">
          <span>{{ row.name }}</span>
        </template>
        <template #cell-shopifyLocation="{ row }">
          <v-select-search
            :data="providerWarehouses"
            placeholder="Select Location"
            :selected="row.providerId"
            @selected="obj => updateWarehouse(obj, row)"
          />
        </template>
      </ez-table>
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>

    <ez-confirmation-modal
      innerClasses="confirmation-modal"
      ref="confirmationModal"
      icon="question"
    >
      <template #title>Confirm Mapped Warehouses</template>
      <template #content>
        <p>
          After these changes are saved, product inventory levels will be synced with your Shopify
          Store.
        </p>
        <p>
          You won’t be able to trigger warehouse mapping until inventory syncing is complete.
        </p>
      </template>
      <template #footer>
        <ez-button-group>
          <ez-button type="link" @click="closeConfirmation">Cancel</ez-button>
          <ez-button @click="mapWarehouses">Confirm</ez-button>
        </ez-button-group>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;

  .subheader {
    display: flex;
    justify-content: space-between;
  }

  :deep() .alert {
    justify-content: flex-start;
  }

  &__info-text {
    @include font-size(14px, 20px);
    width: 400px;
    font-weight: 500;
    color: $color-gray-6C;
  }
}

.save-changes-btn {
  :deep() span {
    display: flex;
  }
}

.loader {
  animation: spin 1.1s infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.sync-table tbody tr td {
  overflow: visible;
}

.name-cell span {
  @include font-size(14px, 22px);
  font-weight: 600;
  color: $color-gray-25;
}
</style>
